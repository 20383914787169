import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import BuyersEtiquette from "../../../pages/Resources/PFD/BuyersEtiquette";
import { HashLink } from "react-router-hash-link";

function Navigation(props) {
  return (
    <Nav>
      <ul className={"main-menu sm sm-clean"}>
        <li>
          <Nav.Link style={{ cursor: "pointer" }} href="/">
            Home{" "}
          </Nav.Link>
        </li>
        <li>
          <NavDropdown menuVariant="dark" id="nav-dropdown" title="About">
            <NavDropdown.Item href="/about">Our Story</NavDropdown.Item>
            <NavDropdown.Item href="/about/our-piece-of-heaven">
              Our Piece of Heaven
            </NavDropdown.Item>
            <NavDropdown.Item href="/about/our-grooming-room">
              Special Poodle Rooms
            </NavDropdown.Item>

            <HashLink
              className="dropdown-item"
              to={"about#standards"}
              elementId={"standards"}
            >
              AKC Standards
            </HashLink>
          </NavDropdown>
        </li>
        <li>
          <NavDropdown menuVariant="dark" id="nav-dropdown" title="Health">
            <NavDropdown.Item href="/danube-health-protocol">
              Danube Natural Rearing Protocol
            </NavDropdown.Item>
            <NavDropdown.Item href="/natural-rearings">
              Natural Rearings Foundations
            </NavDropdown.Item>
            <NavDropdown.Item href="/health-testings">
              Health Testings
            </NavDropdown.Item>
            <NavDropdown.Item href="/maintaining-a-healthy-immune-system">
              Maintaining a Healthy Immune System
            </NavDropdown.Item>
            <NavDropdown.Item href="/spaying-neutering-info">
              Spaying and Neutering Information
            </NavDropdown.Item>
          </NavDropdown>
        </li>
        <li>
          <NavDropdown
            menuVariant="dark"
            id="nav-dropdown"
            title="Our Champions"
          >
            <Nav.Link style={{ cursor: "pointer" }} href="/champions-us">
              United States
            </Nav.Link>
            <Nav.Link style={{ cursor: "pointer" }} href="/champions-eu">
              Europe
            </Nav.Link>
          </NavDropdown>
        </li>
        <li>
          <NavDropdown
            menuVariant="dark"
            id="nav-dropdown"
            title="Are you ready?"
          >
            <NavDropdown.Item href="/not-a-show-dog">
              I want a pet not a show dog
            </NavDropdown.Item>
            <NavDropdown.Item href="/getting-ready">
              Getting ready for a poodle
            </NavDropdown.Item>
            <NavDropdown.Item href="/are-you-ready">
              Are you ready for a poodle?
            </NavDropdown.Item>
            <NavDropdown.Item href="/books-recommendations">
              Book Recommendations
            </NavDropdown.Item>
            <NavDropdown.Item href="/books-recommendations">
              Buyers Etiquette
            </NavDropdown.Item>
          </NavDropdown>
        </li>
        <li>
          <NavDropdown menuVariant="dark" id="nav-dropdown" title="Puppies">
            <NavDropdown.Item href="/litter-plans">
              Litter Plans
            </NavDropdown.Item>
            <NavDropdown.Item href="/past-litters">
              Past Litters
            </NavDropdown.Item>
            <NavDropdown.Item href="/breeding">Breeding</NavDropdown.Item>
          </NavDropdown>
        </li>
        <li>
          <NavDropdown menuVariant="dark" id="nav-dropdown" title="Gallery">
            <NavDropdown.Item href="/gallery">
              Gallery 2007-2022
            </NavDropdown.Item>
            <NavDropdown.Item href="/gallery/PCA/2019">
              PCA 2019
            </NavDropdown.Item>
          </NavDropdown>
        </li>
        <li>
          <Nav.Link style={{ cursor: "pointer" }} href="/testimonials/one">
            Testimonials{" "}
          </Nav.Link>
        </li>
        <li>
          <Nav.Link style={{ cursor: "pointer" }} href="/contact">
            Contact{" "}
          </Nav.Link>
        </li>
        <li>
          <Nav.Link style={{ cursor: "pointer" }} href="/store">
            Store{" "}
          </Nav.Link>
        </li>
      </ul>
    </Nav>
  );
}

export default Navigation;

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPlayer from "react-player";

export default function GenericInfoPage(props) {
  const {
    id,
    title,
    imageName,
    imageName2,
    imageGallery,
    imageGalleryHeight,
    blockQuoteValue,
    preQuoteBody,
    preQuoteHeadings,
    postQuoteBody,
    postQuoteHeadings,
    articleLayout,
    videos,
  } = props;

  return (
    <div className={"single single-post"}>
      <div className={"site-wrapper"}>
        <div id={id} className={"site-content center-relative"}>
          <article style={{ paddingTop: articleLayout && 0 }}>
            <div className={"post-wrapper center-relative"}>
              {title && (
                <h1
                  className={"entry-title"}
                  style={{ paddingTop: articleLayout ? 0 : 50 }}
                >
                  {title}
                </h1>
              )}
              {imageName && (
                <div className={"single-post-featured-image"}>
                  <img
                    style={{ borderRadius: "15px" }}
                    src={require(`../../images/${imageName}`)}
                    alt=""
                  />
                </div>
              )}

              <div
                className={
                  "single-content-wrapper content-1170 center-relative"
                }
              >
                {imageGallery && (
                  <Row
                    style={{ marginBottom: "30px" }}
                    sm={imageGallery.length}
                    xs={imageGallery.length / 2}
                  >
                    {imageGallery.map((image) => {
                      return (
                        <Col style={{ textAlign: "center" }}>
                          <img
                            style={{
                              maxHeight: imageGalleryHeight,
                              borderRadius: "15px",
                            }}
                            src={require(`../../images/${image}`)}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                )}

                <div
                  style={{ marginBottom: articleLayout && 0 }}
                  className={"entry-content"}
                >
                  {preQuoteBody &&
                    preQuoteBody.map((element, index) => {
                      if (preQuoteHeadings) {
                        if (preQuoteHeadings[index] !== "")
                          return (
                            <React.Fragment>
                              <h3>{preQuoteHeadings[index]}</h3>
                              <p>{element}</p>
                            </React.Fragment>
                          );
                        else return <p>{element}</p>;
                      } else return <p>{element}</p>;
                    })}
                  {/* 
                  {videos && (
                    <ReactPlayer url="https://www.facebook.com/messenger_media/?thread_id=1182453570&attachment_id=536604318301591&message_id=mid.%24cAABa83MYZ1KKVxnO32EJdPmk3CRO" />
                  )} */}
                  {blockQuoteValue && (
                    <blockquote>
                      <p>{blockQuoteValue}</p>
                    </blockquote>
                  )}
                  {imageName2 && (
                    <div className={"single-post-featured-image"}>
                      <img
                        style={{ borderRadius: "15px" }}
                        src={require(`../../images/${imageName2}`)}
                        alt=""
                      />
                    </div>
                  )}
                  {postQuoteBody &&
                    postQuoteBody.map((element, index) => {
                      if (postQuoteHeadings) {
                        if (postQuoteHeadings[index] !== "")
                          return (
                            <React.Fragment>
                              <h3>{postQuoteHeadings[index]}</h3>
                              <p>{element}</p>
                            </React.Fragment>
                          );
                        else return <p>{element}</p>;
                      } else return <p>{element}</p>;
                    })}
                </div>
                <div className={"clear"}></div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import SimpleInfoSection from "../../../components/SimpleInfoSection/SimpleInfoSection";
function RawDiet(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="What is wrong with Kibble (Dry) Pet Food?"
        blockQuoteValue="We prefer to get nutrients from real, whole foods that haven't been heavily processed.Our belief in how our poodles should be fed isn't an ideology, but is rooted in real facts."
        postQuoteHeadings={[
          "1. Stomach Acidity",
          "2. Poor Quality Nutrients",
          "3. High Carb/Glycemic",
          "4. Bad for Teeth",
          "5. History of Recalls",
        ]}
        postQuoteBody={[
          "Gastric cells lining the stomach are stimultated to secrete hydrochloric acid that helps convert the precursor enzymes pepsinogen into the protease snzymes pepsin that begins to brakedown of proteins.The issue with processed foods like kibble is that they can reduce the acidity of the gut interfering with the break down of proteins.This can lead to digestive issues, lean muscle loss and inflamtion in the digestive tract over time and realted conditions such as food sensitivities, acid reflux, allergies, and inflammatory bowel disorders.",
          "Processed pet foods are generally formulated with shelf life and profit in mind.The cooking and drying of these foods allow them to be stored on shelves for long periods that leads to the loss of important nutrients. Poor quality ingredients can be hidden behind flashy, misleading advertising.Flavor rnhancers are added to get dogs to eat pet food that they would not otherwise consider to be food.",
          "Most commercial dry pet foods as based on high-glycemic, genetically modified grains and starches that have no place in your pet's diet.Surprisingly , many grain-free dry foods have a higher glycemic index than regular pet foods due to excessive amounts of potatoes, peas, lentils or tapioca included in the formulas.Carbs break down into sugar that fuels conditions such as diabetes, obesity and cancer.",
          "Kibble pet food sticks to your dog or cat;s teeth like potato chips to your teeth.Raw diets help control tartar because meat contains natural enzymes and raw food doesn't stick to teeth like starchy kibbles.Even cooked , whole food diets are much preferred to kibble for oral health.",
          "Processed kibble pet foods have a long history of recalls.Kibble pet foods have sickened numerous dogs over the years due to contaminated gluten, vitamin D toxicity , and other causes that far exceed thous for prepared raw or cooked pet foods.",
        ]}
      />
      <SimpleInfoSection
        title="Readworthy article"
        description="Here's a well written article about dog food... Let's reason and take in consideration the good old commune sense. Click on the link below to learn more."
        content1={
          <div>
            <h6>
              <a href="http://devinefarm.net/rp/rpcoms.htm">
                <u>Common Sense and Nonsense Feeding</u> by Marina Zacharias
              </a>
            </h6>
            <blockquote>
              <p>
                There is little doubt that the vast majority of health problems
                in animals are a direct result of poor nutrition. This is not
                because well meaning owners intentionally feed their companions
                a diet they were never meant to eat, but rather is based on a
                series of "beliefs" initiated and fostered by commercial
                interests in the nine billion dollar a year pet food industry.
              </p>
            </blockquote>
          </div>
        }
      />
      <br />
    </React.Fragment>
  );
}

export default RawDiet;

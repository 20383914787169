import React from "react";
import GenericInfoPage from "../../components/GenericInfoPage/GenericInfoPage";
import Services from "../../components/Home/Services/Services";
import { Link } from "react-router-dom";
import SimpleInfoSection from "../../components/SimpleInfoSection/SimpleInfoSection";
import GenericInfoSection from "../../components/GenericInfoSection/GenericInfoSection";
import image1 from "../../images/health_protocol1.png";
import image2 from "../../images/health_protocol2.png";

export default function AboutPage() {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Welcome to Danube Poodles"
        imageName="about/IMG_2956.webp"
        imageName2="about/about_collage.png"
        videos={[
          "../../images/about/video/video1.mp4",
          "../../images/about/video/video2.mp4",
          "../../images/about/video/video3.mp4",
        ]}
        // imageGallery={[
        //   "about/timi1.webp",
        //   "about/timi2.webp",
        //   "about/timi3.webp",
        //   "about/timi4.webp",
        // ]}
        imageGalleryHeight="200px"
        preQuoteHeadings={["Hello there,"]}
        preQuoteBody={[
          `My name is Timea R. Bodi and I breed quality Miniature Poodles under the name Danube. Growing up, my family and I bred and showed miniature poodles in Europe. Deeply in love with the poodle breed for their intelligence and beauty, devotion and happy joyful spirit. In 2007 I began my own adventure in the USA, as well as in Europe, to continue the family tradition while working as a Veterinary Technician for a decade accumulating knowledge, education and information from accomplished poodle fanciers and from people outside the breed.`,
        ]}
        blockQuoteValue="I am a small hobby breeder and I don't own a kennel. All my poodles live in my house getting lots of attention, care and exercise. I breed for conformation, agility, obedience rings  and my main focus in the breeding program is to have athletic, intelligent, long lived happy family members."
        postQuoteBody={[
          `I fully test all my dogs. Optigen blood test for prcd PRA, specialist ophthalmic SURF exam, hip x-rays with OFA ratings, DNA, dwarfism and  patella luxation. All our puppies are AKC registered and valuated by a vetenarian which provides a  health certificate.`,
          ` I take pride and work hard so that  each puppy generation will be the best representation of the poodle breed.`,
          `My goal is to produce the ultimate poodle, sound mind and body, that can excel in conformation and performance, as well as be the loving companion that will make a great family addition and  your life whole.`,
        ]}
      />
      <SimpleInfoSection
        content1={
          <React.Fragment>
            <iframe
              width="1206"
              height="656"
              src="https://www.youtube.com/embed/C61bgm0NTv8"
              title="Raising Poodles Naturally"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <br />
            <br />
            <br />
            <h5 style={{ textAlign: "center" }}>
              Learn more about our way of breeding.
              <br />
              See exactly where we raise and nurture our poodles plus our
              dedicated grooming, whelping and fun puppy areas.
            </h5>
            <br />

            <div
              style={{ textAlign: "center" }}
              className={"button-holder text-left"}
            >
              <Link className={"button"} to="our-piece-of-heaven">
                Our piece of Heaven
              </Link>{" "}
              <Link className={"button"} to="our-grooming-room">
                Our Special Poodle Rooms
              </Link>
            </div>
            <br />
            <br />
          </React.Fragment>
        }
      />
      {/* <GenericInfoPage
        articleLayout="true"
        preQuoteHeadings={["Our little piece of Heaven"]}
        preQuoteBody={[
          `This is our little piece of heaven where Danube Poodles get to enjoy fresh air, run and play all day long. We live in middle of Tennessee on a 10 acre land surrounded by God's nature beauty , rivers and picturesque waterfalls. Here is where our poodles get to learn to sharpen their senses chasing rabbits, butterflies ,birds and share undivided time and attention from family and friends.`,
        ]}
        blockQuoteValue="We feel getting into the nitty gritty of the breed is really what advances not only the individual poodle, but the breed as a whole."
      /> */}
      <Services
        buttonIsAvailable="true"
        paddingTop="5rem"
        smallerHeading={true}
        bigText="Danube is the biggest river in Europe passing through Romania and
            Hungary. We chose this name to represent our poodles because it is
            inspiring us to dream big and to represent our roots that unites us
            through a legacy of poodle generations giving us a plethora of genetics
            and timeless connections. And that is exactly our over arching goal.
            Besides the genetic diversity we achieve this in several ways:"
        first={{
          title: "HEALTH",
          description:
            "Health is incredible important for any living being. If a creature is not healthy they cannot live a happy and fulfilling life.",
          number: "1",
          extraDescription:
            "Health is incredible important for any living being. If a creature is not healthy they cannot <br/> live a happy and fulfilling life. All our poodles and puppies  are evaluated and recorded <br/> on a regular basis for health. We are proud advocates on screenings to ensure our poodles are free of hereditary diseases. <br/> We are proud to provide our poodles with various forms of enrichment and stimulation within their environment and in our home.",
        }}
        second={{
          title: "EXERCISE",
          description:
            "Exercise is never optional. Animals are meant to move almost constantly. In the wild, playing, moving from place to place, and hunting are done daily.",
          number: "2",
          extraDescription: "",
        }}
        third={{
          title: "SUN",
          description:
            "Animals instinctually know what is needed to stay healthy (see Zoopharmacognocy). ",
          number: "3",
          extraDescription: "",
        }}
      ></Services>
      <Services
        buttonIsAvailable="true"
        paddingTop="0"
        smallerHeading={true}
        first={{
          title: "SUPPLEMENTS",
          description:
            "Supplements are especially needed when a raw , species specific diet is not being fed and/ or when the animal is already in a dis-eased state.",
          number: "4",
          extraDescription: "",
        }}
        second={{
          title: "TEMPERANCE",
          description:
            "Temperance is an old-fashioned word for moderation. Too much of even a good thing can wind up being detrimental",
          number: "5",
          extraDescription: "",
        }}
        third={{
          title: "AIR",
          description:
            "Every living thing needs fresh air daily if possible. Fresh air energizes.",
          number: "6",
          extraDescription: "",
        }}
      ></Services>
      <Services
        buttonIsAvailable="true"
        paddingTop="0"
        smallerHeading={true}
        first={{
          title: "REST",
          description:
            "Companion animals need plenty of quality rest. They do best without all the noise of modern appliances and electronic EMFs.",
          number: "7",
          extraDescription: "",
        }}
        second={{
          title: "TRUST",
          description:
            "Trust is not something very many people think about as being a law of nature and health, but it is the eighth law. ",
          number: "8",
          extraDescription: "",
        }}
        third={{
          title: "TEMPERAMENT",
          description:
            "Although not every poodle will do therapy work or enter the show ring, a great out going, social and loving personality is characteristic of the breed.",
          number: "9",
          extraDescription: "",
        }}
      ></Services>
      <Services
        buttonIsAvailable="true"
        paddingTop="0"
        smallerHeading={true}
        ackImage={true}
        image1={image1}
        first={{
          title: "GENETICS",
          description:
            "In order to truly produce impeccable poodles that not only advance the individual but breed, one needs an understanding of poodle genetics.",
          number: "10",
          extraDescription: "",
        }}
        second={{
          title: "NUTRITION",
          description:
            "Nutrition is the fuel of life.Without proper diet domestic dogs of all kinds cannot live to their full potential 100%. They are merely surviving versus thriving.",
          number: "11",
          extraDescription: "",
        }}
        third={{
          title: "STANDARD",
          description:
            "Although brilliant color and the right point pigmentation are characteristics of the breed, there is certainly more to it than flash.",
          number: "12",
          extraDescription: "",
        }}
      ></Services>
      <GenericInfoSection
        id="standards"
        title="AKC Poodle Breed Standard"
        sectionTitle="THE HIGHEST STANDARDS"
        titleDescription="OUR POODLES ARE THE BEST. REALLY."
        images="true"
        image1={image1}
        image2={image2}
        description="Poodles are among the greatest companions. And our poodles are the best out there. We abide to the heighest standards and we pride ourselves in bringing them up with love and joy."
        content1={
          <React.Fragment>
            <br />
            <br />
            <p>
              See for yourself what that entails. Click below to download or
              print the AKC Poodle Breed Standard in order to see what we are
              working hard to represent. You can also check below the Poodle
              History to see what we mean by "the greatest companions".{" "}
            </p>
            <br />

            <div className={"button-holder text-left"}>
              <a
                className={"button"}
                href="https://www.akc.org/dog-breeds/poodle-standard/#timeline"
              >
                AKC Poodle Breed Standard
              </a>{" "}
              <a
                className={"button"}
                href="http://cdn.akc.org/Poodle-club-flier.pdf?_ga=1.205349200.1940403961.1470613763"
              >
                Poodle History
              </a>
            </div>
            <br />
          </React.Fragment>
        }
      ></GenericInfoSection>
    </React.Fragment>
  );
}

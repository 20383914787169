import React from "react";
import GenericInfoPage from "../../../components/GenericInfoPage/GenericInfoPage";
import SimpleInfoSection from "../../../components/SimpleInfoSection/SimpleInfoSection";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import TextAndTwoButtons from "../../../components/TextAndTwoButtons/TextAndTwoButtons";

function Vaccination(props) {
  return (
    <React.Fragment>
      <GenericInfoPage
        title="Damage that is caused by vaccinations is forever"
        blockQuoteValue={
          <div>
            <p>
              If I may venture to make a prediction, it is that fifty to one
              hundred years from now, people will look back at the practice of
              introducing diseases into people and animals for the purpose of
              preventing these same diseases as foolishness ... a foolishness
              similar to that the practice of of blood letting and the use of
              toxic doses of mercury in the treatment of disease.
              <br /> ⎯⎯{" "}
              <span style={{ color: "#2b7a0b" }}>
                {" "}
                Richard H Pitcairn, DVM, PHD ( Immunology )
              </span>
            </p>
          </div>
        }
        postQuoteHeadings={["", "", "", ""]}
        postQuoteBody={[
          "There are so many harmful aspects to a vaccination that even knowing where to start is hard.  I don’t want you to just take my word for that so many links will be provided for you to research on your own.",
          "Have you thought about how a vaccination introduces a disease to your pet versus how that disease would be introduced in the wild?  Imagine a dog walking through the woods and encountering the Parvo Virus for example.  How would that dog be exposed to it?  It would be absorbing the exposure through it’s paw pads and its mucous membranes.  When vaccinating your dog they are being subjected to the disease either weakened and live or by a killed virus and this is done into the dogs muscle.  This is not a normal way for a dog to encounter a disease at all.",
          "In addition to being subjected to the disease in such an unnatural way the dog is also exposed to many other toxins.  Vaccines carry numerous other toxins such as but not limited to Aluminum,Formaldehyde (a product to preserve dead bodies), and  Thimersol (an antiseptic and reservative).  Many other toxins are found in vaccinations for animals as well as people.  Not only is your pet being exposed to harmful, deadly toxins but the vaccinations don’t always protect against all the strains of the disease that they are suppose to be protecting against.  For instance the Parvo vaccine only covers a particular number of strains however new strains are always developing and the vaccination does not protect your pet from those strains.  It also does not guarantee that your pet will not be affected by Parvo but it does guarantee to weaken your pets immune system so that if it does get sick it may not be able to fight off the disease. Many dogs die from Parvo who are vaccinated!  Parvo is not the only vaccination that does damage than good.",
          <h6>Please check out the links below and make your own judgment</h6>,
          <div>
            <a
              className={"button"}
              href="https://www.cyberpet.com/dogs/health-care/"
            >
              Cyberpet Vaccinosis
            </a>{" "}
            <a
              className={"button"}
              href="http://www.naturalrearing.com/coda/a_rabies_the_big_scam.html"
            >
              Rabies Vaccinations
            </a>{" "}
            <a
              className={"button"}
              href="http://www.naturalrearing.com/coda/a_science_of_vaccine_damage.html"
            >
              Science of Vaccine Damage
            </a>{" "}
            <a
              className={"button"}
              href="http://www.sunherb.com/truth_about_vaccines.htm"
            >
              SunHerb's The Truth About Vaccines
            </a>{" "}
            <a
              className={"button"}
              href="https://www.shirleys-wellness-cafe.com/AnimalWellness/Petvacc"
            >
              Pet Vaccination Dangers
            </a>{" "}
            <a
              className={"button"}
              href="http://www.dogsandhorses.net/vaccinations.html"
            >
              Research Before You Vaccinate
            </a>{" "}
            <a
              className={"button"}
              href="https://www.naturalrearing.com/coda/a_rabies_the_big_scam.html"
            >
              Rabies Damage
            </a>{" "}
            <a
              className={"button"}
              href="http://naturalrearing.com/coda/a_science_of_vaccine_damage.html"
            >
              Science behind vaccines
            </a>{" "}
          </div>,
        ]}
      />
      <SimpleInfoSection
        id="millstones"
        content1={
          <div style={{ color: "#ffffff" }}>
            <h5 style={{ textAlign: "center", paddingTop: "3em" }}>
              {" "}
              Vaccine Ingredients
            </h5>
            <h6 style={{ textAlign: "center" }}>
              Here's a list of ingredients commonly found in vaccinations
            </h6>
            <br />
            <br />
            <Row
              style={{
                textAlign: "center",
                border: "2px #880000 solid",
                marginBottom: "50px",
              }}
            >
              <Col>
                <p></p>
                <p>aluminum hydroxide</p>
                <p>aluminum phosphate</p>
                <p>ammonium sulfate</p>
                <p>amphotericin B</p>
                <p>animal tissues: pig blood, horse blood, rabbit brain</p>
                <p>dog kidney, monkey kidney</p>
                <p>chick embryo, chicken egg, duck egg</p>
                <p>calf (bovine) serum</p>
                <p>betapropiolactone</p>
                <p>fetal bovine serum</p>
                <p>formaldehyde</p>
                <p>formalin</p>
                <p>gelatin</p>
                <p>glycerol</p>
                <p>
                  human diploid cells (originating from human aborted fetal
                  tissue)
                </p>
                <p>hydrolized gelatin</p>
                <p>mercury thimerosol</p>
              </Col>
              <Col>
                <p></p>
                <p>monosodium glutamate (MSG)</p>
                <p>neomycin</p>
                <p>neomycin sulfate</p>
                <p>phenol red indicator</p>
                <p>phenoxyethanol (antifreeze)</p>
                <p>potassium diphosphate</p>
                <p>potassium monophosphate</p>
                <p>polymyxin B</p>
                <p>polysorbate 20</p>
                <p>polysorbate 80</p>
                <p>porcine (pig) pancreatic hydrolysate of casein</p>
                <p>residual MRC5 proteins</p>
                <p>sorbitol</p>
                <p>sucrose</p>
                <p>tri(n)butylphosphate</p>
                <p>VERO cells, a continuous line of monkey kidney cells</p>
                <p>washed sheep red blood</p>
              </Col>
            </Row>
            <br />
            <br />
          </div>
        }
      />
      <br />
      <GenericInfoPage
        articleLayout="true"
        blockQuoteValue={
          <div style={{ textAlign: "center" }}>
            <p>
              If you knew when you gave a vaccination that you might save an
              animal from an episode of an acute disease, and you also knew that
              you would be sentencing the animal to a lifetime of chronic
              disease, would you still vaccinate?
              <br /> ⎯⎯{" "}
              <span style={{ color: "#2b7a0b" }}>
                {" "}
                Richard H Pitcairn, DVM, PHD ( Immunology )
              </span>
            </p>
          </div>
        }
      />
      <TextAndTwoButtons
        button1="Go Back"
        button2="Learn More Anout Us"
        href2="/about"
      />
    </React.Fragment>
  );
}

export default Vaccination;

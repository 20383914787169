import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import ScrollBottomButton from "../ScrollButton/ScrollBottomButton";
import ScrollTopButton from "../ScrollButton/ScrollTopButton";

class Footer extends Component {
  render() {
    return (
      <footer className={"footer"}>
        <div className={"footer-content center-relative"}>
          <div className={"footer-logo"}>
            <Link to={"/"}>
              <img
                style={{ borderRadius: "100%" }}
                src={require("../../images/newLogos/img_1.png")}
                alt="Danube poodels Logo"
              />
            </Link>
          </div>

          {/* <div className={"footer-logo-divider"}></div> */}
          <div className={"footer-mail"}>
            <a href="mailto:danubepoodles@yahoo.com">danubepoodles@yahoo.com</a>
          </div>
          {/* <div className={"footer-social-divider"}></div> */}
          <div className={"social-holder"}>
            <a
              style={{ fontSize: "35px" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/danubepoodles"
            >
              <FaInstagram />
            </a>
            <a
              style={{ fontSize: "35px" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/danubepoodles"
            >
              <FaFacebookF />
            </a>

            <div
              style={{ marginTop: "25px" }}
              className={"footer-logo achievements"}
            >
              <img src={require("../../images/ack.jpg")} alt="ack" />
              <img
                src={require("../../images/footer-nr.png")}
                alt="Natural Rearer"
              />
            </div>
          </div>

          <div className={"copyright-holder"}>
            © Danube Poodles | All rights reserved <br />
            <footnote style={{ color: "#484848", fontSize: "11px" }}>
              Created by{" "}
              <a style={{ color: "#484848" }} href="https://alevla.com/">
                ALEVLA
              </a>
            </footnote>
          </div>
        </div>
        <ScrollTopButton />
        <ScrollBottomButton />
      </footer>
    );
  }
}

export default Footer;
